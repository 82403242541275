body {
	font-family: $font-jp;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	color: $default-color;
}

img{
	max-width: 100%;
}

img[src$=".svg"] {
	width: 100%;
	height: auto;
}


//--------------------------------------------------------------
.header {
	width: 100%;
	height: 90px;
	background: url("/assets/img/common/header_bg.jpg") center top repeat-x;
	padding: 0 40px;

	@include max_screen($break-point) {
		padding: 0;
		height: 50px;
	}

	&__inner {
		width: 100%;
		height: 100%;
		max-width: 900px;
		margin: 0 auto;
		position: relative;
		@include txt_link_opacity(none, $color-white);
	}

	&__logo {
		position: absolute;
		top: 35px;
		left: 0;

		@include max_screen($break-point) {
			width: 160px;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);
		}
	}

	&__tel {
		font-family: helvetica, arial, sans-serif;
		font-size: 27px;
		letter-spacing: .02em;
		line-height: 1;
		position: absolute;
		top: 35px;
		right: 0;
		font-weight: bold;

		&:before {
			content: "";
			background: url("/assets/img/common/icon_tel.png") no-repeat;
			background-size: 100% auto;
			width: 24px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: -28px;
			transform: translateY(-50%);
		}

		@include min_screen($break-point + 1px) {
			a {
				pointer-events: none;
			}
		}

		@include max_screen($break-point) {
			position: relative;
			top: auto;
			right: auto;
			margin: 0 auto;
			padding-left: 28px;
			display: inline-block;

			&:before {
				left: 0;
			}
		}
	}

	&__navi {
		position: absolute;
		top: 37px;
		right: 237px;
		display: flex;

		&__item {
			margin-right: 30px;
			font-weight: 500;

			&:last-child {
				margin-right: 0;
			}
		}

		@include max_screen($break-point) {
			position: static;
			width: 100%;
			display: block;
			margin-bottom: 20px;

			&__item {
				width: 100%;
				text-align: center;
				font-size: 18px;
				border-bottom: 1px solid #74a78c;

				a {
					display: block;
					padding: 20px 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.navi-wrap {
		@include max_screen($break-point) {
			width: 100%;
			position: absolute;
			top: 50px;
			left: 0;
			right: auto;
			background: url("/assets/img/common/header_bg.jpg");
			background-size: cover;
			padding: 0 0 20px;
			text-align: center;
			border-top: 1px solid #74a78c;
			display: none;

			.navi-open & {
				display: block;
			}
		}
	}

	&__navi-btn {
		display: none;

		@include max_screen($break-point) {
			display: block;
			position: absolute;
			top: 18px;
			right: 20px;
			height: 14px;
			width: 16px;
			/*padding: 22px 20px;*/
			z-index: 210;
			cursor: pointer;

			.navi-open & {
				span:nth-child(1) {
					top: 6px;
					transform: rotate(45deg);
				}

				span:nth-child(2) {
					opacity: 0;
				}

				span:nth-child(3) {
					bottom: 6px;
					transform: rotate(-45deg);
				}
			}

			span {
				width: 16px;
				height: 2px;
				background: $color-white;
				display: block;
				position: absolute;
				@include transition;
			}

			span:nth-child(1) {
				top: 0;
				left: 0;
			}

			span:nth-child(2) {
				top: 6px;
				left: 0;
			}

			span:nth-child(3) {
				bottom: 0;
				left: 0;
			}
		}
	}
}


//--------------------------------------------------------------
.footer {
	width: 100%;
	margin-top: 150px;
	padding: 60px 40px;
	background: #444;
	color: $color-white;

	@include max_screen($break-point) {
		margin-top: 100px;
		padding: 30px 20px;
	}

	&__inner {
		width: 100%;
		max-width: 900px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@include max_screen($break-point) {
			display: block;
		}
	}

	&__address {
		font-size: 13px;
		line-height: 2;
		letter-spacing: .075em;
		@include txt_link_color(underline, $color-white, $color-white);

		.name {
			font-weight: 500;
			font-size: 15px;
			display: block;
			margin-bottom: 5px;
		}

		@include max_screen($break-point) {
			margin-bottom: 30px;
		}
	}

	&__copyright {
		font-size: 12px;
		letter-spacing: .075em;
	}
}

