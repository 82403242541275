#top {

	//-----------------------------------------------------
	.section-heading {
		margin-bottom: 70px;
		text-align: center;

		.jp {
			font-size: 34px;
			letter-spacing: .075em;
			line-height: 1;
			margin-bottom: 24px;
			font-weight: 700;
		}

		.en {
			font-size: 14px;
			letter-spacing: .075em;
			line-height: 1;
			color: $color-green;
			font-weight: 700;
		}

		@include max_screen($break-point) {
			margin-bottom: 40px;

			.jp {
				font-size: 28px;
				margin-bottom: 15px;
			}
		}
	}


	//-----------------------------------------------------
	.mv {
		width: 100%;
		height: 590px;
		background: #ddd url("/assets/img/top/mv/pic.jpg") no-repeat center top;
		background-size: cover;
		margin-bottom: 100px;
		padding: 0 40px;

		&__inner {
			width: 100%;
			max-width: 900px;
			margin: 0 auto;
		}

		&__catch {
			padding-top: 53px;
		}

		@include max_screen($break-point) {
			padding: 0 20px;
			height: 300px;
			margin-bottom: 60px;

			&__catch {
				padding-top: 30px;
			}
		}
	}

	//-----------------------------------------------------
	.message {
		width: 100%;
		margin-bottom: 100px;
		padding: 0 40px;

		&__inner {
			width: 100%;
			max-width: 900px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		&__pic {
			width: percentage(400 / 900);
		}

		&__txt {
			width: percentage(450 / 900);
			font-size: 16px;
			line-height: percentage(48 / 16);
			letter-spacing: .075em;
			font-weight: 500;
		}

		@include max_screen($break-point) {
			padding: 0 20px;
			margin-bottom: 60px;

			&__inner {
				display: block;
			}

			&__pic {
				width: 100%;
				margin-bottom: 20px;
			}

			&__txt {
				width: 100%;
				line-height: 2;
				font-size: 15px;
			}
		}
	}


	//-----------------------------------------------------
	.business {
		width: 100%;
		padding: 60px 40px 80px;
		margin-bottom: 100px;
		background: $color-lightgray;

		@include max_screen($break-point) {
			padding: 40px 20px 40px;
			margin-bottom: 60px;
		}

		&__inner {
			width: 100%;
			max-width: 900px;
			margin: 0 auto;
		}

		&__txt {
			font-weight: 500;
			line-height: 1.8;
			text-align: center;
			letter-spacing: .075em;
			margin-bottom: 50px;

			@include max_screen($break-point) {
				text-align: left;
				margin-bottom: 30px;
				line-height: 1.6;
				font-size: 14px;
			}
		}

		&__list {
			width: 100%;
			display: flex;
			justify-content: space-between;

			&__item {
				width: percentage(270 / 900);
				text-align: center;

				.ttl {
					font-weight: 500;
					margin-top: 20px;
					font-size: 16px;
					letter-spacing: .075em;
				}
			}

			@include max_screen($break-point) {
				display: block;

				&__item {
					width: 100%;
					margin-bottom: 30px;

					&:last-child {
						margin-bottom: 0;
					}

					.ttl {
						margin-top: 14px;
					}
				}
			}
		}
	}


	//-----------------------------------------------------
	.profile {
		width: 100%;
		padding: 0 40px;

		@include max_screen($break-point) {
			padding: 0 20px;
		}

		&__inner {
			width: 100%;
			max-width: 650px;
			margin: 0 auto;
		}

		&__list {
			width: 100%;
			margin: 0 auto;
			border-top: 1px solid #ccc;

			&__item {
				padding: 16px 20px;
				display: flex;
				font-size: 16px;
				font-weight: 500;
				letter-spacing: .075em;
				border-bottom: 1px solid #ccc;
				line-height: percentage(28 / 16);
				@include txt_link_color(unerline, $color-green, $color-green);

				dt {
					width: 175px;
					min-width: 175px;
				}

				dd {
					width: 100%;
				}

				@include max_screen($break-point) {
					font-size: 15px;
					padding: 16px 0;

					dt {
						width: 100px;
						min-width: 100px;
					}
				}
			}
		}
	}


	//-----------------------------------------------------
}
